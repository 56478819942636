.not-found-image-layout{
    height: 90vh;
    margin-top: 3rem;
    margin-left: auto;
    display: flex;
}

.not-found-image{
    height: 30rem;
    width: 30rem;
    margin-left: auto;
    margin-right: auto;
}

.back-button{
    margin-top: 0.5rem;
    margin-left: 1rem;
}

.back-button-option-layout{
    background-color: #ecf4f4;
    height: 3.30rem;
    margin: auto;
}