
.site-card-wrapper {
    overflow-y: scroll;
    // background-color: red;   
    // background-color: red;
    padding: 0px !important;
    padding-right: 0px !important;
}

.row {
    padding: 5px;
}

.send-notification-button {
    background-color: var(--PrimaryColor);
    font-weight: 600;
    margin: 5px;
}

.send-notification-button:hover{
    background-color: var(--PrimaryRippleColor) !important;
}

.border-right {
    border-right: 1px solid #e8e8e8;
    font-weight: bold;
}

.notification-title{
    font-size: 1.1rem !important;
    font-weight: 600;
    border-right: 1px solid #aeaeae;
    display: flex;
}

.notification-title-text{
    margin-top: auto;
    margin-bottom: auto;
}

.notification-option-button{
    padding-left: 2rem !important;
}

.second-notification-option-button{
    margin-left: 1rem;
}

.notification-card-layout{
    margin-top: 1rem;
}

.send-notification-model{
    .ant-form-item-label label{
        font-size: 1rem;
        margin-top: 1rem;
    }
}