.payment-service-image{
    height: 9rem !important;
    width: 7rem !important;
    border-radius: 10px;
}

.service-payment-details-layout .ant-card-body{
    padding: 0.5rem !important;
    padding-bottom: 1.2rem !important;
    border-bottom: 1px solid #dadada;
}

.payment-service-details{
    margin-left: 1rem;
}

:where(.css-dev-only-do-not-override-2i2tap).ant-card:not(.ant-card-bordered) {
    box-shadow: unset !important;
}

.payment-details-modal{

    .ant-modal-body{
        max-height: 80vh;
        overflow-y: auto;
    }

    .service-name{
        font-size: 1.1rem;
        font-weight: 700;
        width: 100%;
    }

    .service-price{
        font-size: 1rem;
        margin-left: auto;
    }

    .service-price span{
        font-size: 0.90rem;
        font-weight: 700;
    }

    
    .payment-data{
        margin-top: 0.4rem;
    }

    .payment-data-title{
        font-size: 0.90rem;
        font-weight: 700;
        margin-top: auto;
        margin-bottom: auto;
    }

    .payment-data-information{
        font-size: 0.90rem;
        margin-bottom: auto;
        margin-left: 0.30rem;
    }

    .transaction-data{
        background-color: #f4f4f4;
        width: fit-content;
        margin-top: 0.7rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        text-align: left;
        border-radius: 5px;
        cursor: pointer;
    }

    .transaction-data:hover{
        background-color: #e4e4e4;
    }

    // table related attributes css 

    .table-service-image{
        height: 3rem !important;
        width: 3rem !important;
        border-radius: 3rem;
    }

    .table-service-name{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0.40rem;
    }
}