.vendor-document-image{
    height: 20rem !important;
    width: 30rem !important;
}

.vendor-document-title{
    font-size: 1rem ;
    justify-content: center;
    text-align: center;
    margin-bottom:3rem;
    text-transform: uppercase;
}


.slick-dots li button {
    background-color: var(--PrimaryColor) !important ; 
}