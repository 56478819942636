.vendor-question-modal {
    .ant-modal-content {
        max-height: 85vh;
        overflow-y: hidden;
    }

    .ant-modal-body{
        max-height: 83vh;
        overflow-y: auto;
    }

    .ant-collapse{
        border: 0px !important;
        border-radius: 0px !important;
    }

    .ant-collapse-header-text{
        font-weight: 600 !important;
        font-size: 0.95rem !important;
    }
}

