.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root{
  --PrimaryColor : #007074 ; 
  --PrimaryRippleColor: #005456 ; 
  --Background-color : #ecf0f4 ; 

  --Slidebar_background_color : #FFFFFF ; 

  --Text_primary_color : #212121 ; 
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #99999984;
}

body{
  font-family: 'Poppins', sans-serif !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login-page-wrapper {
  align-items: center;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  background-color: var(--Background-color);

  .login-card-wrapper {
    display: flex !important;
    flex-direction: column;
    justify-content: center;

    .login-card {
      min-width: 485px;

      .ant-card-body {
        border-radius: 10px;
        box-shadow: 0 0 13px 1px rgb(0 0 0 / 15%);
        padding: 45px 40px;

        .logo-container {
          display: flex;
          justify-content: center;
          padding: 2rem 0;

          .logo {
            border-radius: 10px;
            margin: 0 auto;
            height: 50px;
            width: 100px;
            object-fit: contain;
            background: rgb(255, 255, 255);
          }
        }

        .card-header {
          align-items: flex-end;
          display: flex;
          justify-content: space-between;

          h2 {
            font-size: 20px;
            margin-inline: auto;
            font-weight: 600;
            color: #1677ff;
          }
        }
      }
    }
    .signup-logo {
      border-radius: 10px;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      height: 80px;
      width: 230px;
      object-fit: contain;
      background: rgb(255, 255, 255);
    }
  }
}

.mb {
  margin-bottom: 10px;
}

.login-from-header{
  color: var(--PrimaryColor) !important;
  font-weight: 700 !important;
}

.login-form-button {
  align-items: center;
  border: none;
  border-radius: 12px;
  color: #fff;
  display: flex;
  font-weight: 700;
  height: 55px;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  background-color: var(--PrimaryColor);

  &:hover {
    opacity: 0.8 !important;
  }
}

.ant-form-item-required{
  font-weight: 600 !important;
  font-size: 1.1rem;
}

.remember-option-text{
  font-weight: 600 !important;

}

.ant-table-thead > tr > th {
  background-color: var(--PrimaryColor) !important; 
  color: #FFFFFF !important ;
}

.ant-select-selector{
  background-color:  var(--PrimaryColor) !important;
  color: #FFFFFF !important;
}

.ant-table-cell-scrollbar{
  background-color: var(--PrimaryColor) !important;
}

.ant-select-arrow{
  color: #FFFFFF !important;
}

.ant-table-cell{
  padding: 8px !important;
  font-weight: 500 !important;
}

.user-delete{
  background-color: #FFFFFF !important;
  box-shadow: none !important;
  width: fit-content !important;
}

.create-button{
  background-color: var(--PrimaryColor) !important;
}

.table-information-layout{
  width: 100vw;
  overflow-y: hidden;
  height: 100vh;
}

.breadcumb-information{
  height: 5vh;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem; 
  padding-bottom: 2rem;
  background-color: #FFFFFF;
  border-bottom: 1px solid #e5e5e5;
}

.table-data{
  max-height: 93vh;
  overflow-y: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.table-option{
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 1rem;
}

.spin-layout{
  width: 100% !important;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.spinner-info{
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.ant-table-wrapper{
  margin-top: 0.2rem;
}

.ant-breadcrumb{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ant-breadcrumb-link{
  font-size: 1rem;
  font-weight: 700;
}

.slidebar-title{
  text-align: center;
  font-weight: 650 !important;
  color: var(--PrimaryColor) !important;
}

.ant-layout-sider-children{
  padding-left: 0.60rem !important;
  padding-right: 0.60rem !important;
}

.ant-menu{
  border-inline-end: 0px !important ; 
}

.ant-menu-title-content{
  font-weight: 600 !important;
}

.category-action-option{
  margin-right: 0.80rem !important;
}

.create-category-button{
  background-color: var(--PrimaryColor);
  font-weight: 600;
}

.create-category-button:hover{
  background-color: var(--PrimaryRippleColor) !important;
}

/* ==== Model related css ====  */

.ant-modal-content{
  padding: 0px !important;
  padding-bottom: 1.5rem !important;
}

.ant-modal-header{
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--PrimaryColor) !important;
}

.ant-modal-title{
  color: #ffffff !important;
}

.ant-modal-close-icon{
  color: #ffffff !important;
}

.ant-modal-footer{
  padding-right: 1rem !important;
}

.citizencare-model .ant-btn-primary {
  background-color: var(--PrimaryColor);
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.citizencare-model .ant-btn-primary:hover {
  background-color: var(--PrimaryRippleColor) !important;
}


.category-data-information{
  display: flex;
  flex-direction: row;
  text-align: center;
  cursor: pointer;
}

.category-image{
  height: 32px;
  width: 32px;
  border-radius: 40px; 
  margin-top: auto;
  margin-bottom: auto;
}

.category-name{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.50rem;
}

/* ===== Category create layout model design =====  */

.category-create-layout{
  padding-left: 1rem;
  padding-right: 1rem;
}


/* ===== Update upload item css =====  */

.ant-upload-list-item {
  border-color: var(--PrimaryColor) !important;
}

.ant-upload-list-item-name{
  color: var(--PrimaryColor) !important;
}

.anticon-delete{
  color: var(--PrimaryColor) !important;
}

/* ===== User information related table css =====  */

.user-profile-image{
  height: 80px;
  width: 80px;
  border-radius: 60px !important;
}

.user-profile-data{
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem ;
}

.user-profile-title{
  font-family: "Poppins";
  font-size: 0.80rem;
  font-weight: 600;
  color: #FFFFFF;
  background-color: var(--PrimaryRippleColor);

  padding-left: 0.50rem;
  padding-right: 0.50rem;
  padding-top: 0.30rem;
  padding-bottom: 0.30rem;

  border-radius: 5px;

  margin-top: auto;
  margin-bottom: auto;
}

.user-profile-title-data{
  font-family: "Poppins";
  font-size: 0.80rem;
  color: #7F8388;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.60rem;
}

/* ==== Antd drawer css =====  */

.ant-drawer-header{
  background-color: var(--PrimaryColor) !important;
}

.ant-drawer-title{
  color: #FFFFFF !important;
}

.anticon-close{
  color: #FFFFFF !important;
}

.admin-panel-layout{
  background-color: #ecf4f4 !important; 
}

body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.anticon-search{
  color: #ffffff !important;
  margin-right: 10px;
}

.search-filter-option-button{
  background-color: var(--PrimaryColor);
}
.search-filter-option-button:hover{
  background-color: var(--PrimaryRippleColor) !important;
}

.filter-option-button span{
  color: var(--PrimaryColor);
}

.filter-close-button span{
  color: rgb(255, 41, 41) ; 
}

.bank-details-table{
  .ant-table-thead th{
    background-color: #E5E4E2	!important;
    color: #000000 !important;
  }
}

.payment-details-table{
  .ant-table-thead th{
    background-color: #E5E4E2	!important;
    color: #000000 !important;
  }
}

.total_paid_amount_tag{
  font-size: 1rem !important;
  margin-left: 0.70rem !important; 
  padding-top: 0.40rem;
  padding-bottom: 0.40rem;
  margin-top: 0.60rem;
  margin-bottom: 0.60rem;
}

.ant-popconfirm-buttons button:nth-child(2) {
  background-color: var(--PrimaryColor);
}

.ant-popconfirm-buttons button:nth-child(2):hover {
  background-color: var(--PrimaryRippleColor) !important; 
}

