$Primary-ripple-color: #00707420 ; 

.ant-layout-sider{
    background-color: var(--Slidebar_background_color) !important;
}

.ant-menu{
    background-color: var(--Slidebar_background_color) !important;
}

.ant-menu-title-content span{
    color: var(--Text_primary_color);
    font-size: 1rem;
}

.slidebar-title{
    background-color: $Primary-ripple-color;
    padding-top: 0.50rem;
    padding-bottom: 0.50rem;
    border-radius: 10px;
}

.ant-menu-item-active{
    background-color: $Primary-ripple-color !important;
    color: #FFFFFF !important;
}

.ant-layout-sider-trigger{
    background-color: $Primary-ripple-color !important;
}

.anticon-left{
    color: #FFFFFF !important;
}

.ant-menu-item-selected{
    background-color: $Primary-ripple-color !important;
    color: #FFFFFF !important;
}

.cetizencare-logo{
    height: 5rem;
    width: 100%;
}

.slidebar-logout-option .ant-menu-item{
    color: red !important;
}

.anticon-left{
    color: var(--Text_primary_color) !important;
}